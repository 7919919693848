import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import "@/assets/css/style.css";

import axios from "axios";
// axios.defaults.baseURL = 'http://127.0.0.1:8000/'
axios.defaults.baseURL = 'https://api-staging.helocus.com/'
// axios.defaults.baseURL = 'http://192.168.1.3:8000/'

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error);
    if(error.response.status === 401) {
      localStorage.removeItem("token");
      router.push("/login");
    }
    return Promise.reject(error);
  }
)



const app = createApp(App)

app.use(VueSweetalert2);
app.use(router);

app.mount('#app');
